<template>
  <page-title
    class="q-pl-lg q-mb-lg"
    :title="$t('account.change_password')"
  ></page-title>
  <q-intersection
    class="full-height full-height-intersection"
    transition="slide-up"
  >
    <q-card
      class="default-rounded full-height default-shadow"
      :class="{ 'q-mb-lg': $q.platform.is.mobile }"
    >
      <q-ajax-bar
        ref="bar"
        position="bottom"
        color="blue"
        size="10px"
        skip-hijack
      />

      <q-card-section class="q-pa-none form-inputs-content">
        <Form
          class="q-gutter-md"
          :initial-values="initialValues"
          :validation-schema="schema"
          @submit="onSubmit"
        >
          <q-card-section>
            <Field
              name="currentPassword"
              v-slot="{ errorMessage, value, field }"
            >
              <q-input
                filled
                type="password"
                :debounce="900"
                autocomplete="on"
                :dense="$q.platform.is.mobile"
                :label="labels.currentPassword"
                :model-value="value"
                v-bind="field"
                :error-message="errorMessage"
                :error="!!errorMessage"
              >
                <template v-slot:before><q-icon :name="'lock'"/></template>
              </q-input>
            </Field>

            <Field name="password" v-slot="{ errorMessage, value, field }">
              <q-input
                filled
                type="password"
                autocomplete="off"
                :dense="$q.platform.is.mobile"
                :label="labels.newPassword"
                :model-value="value"
                v-bind="field"
                :error-message="errorMessage"
                :error="!!errorMessage"
              >
                <template v-slot:before><q-icon :name="'password'"/></template>
              </q-input>
            </Field>

            <Field
              name="passwordConfirmation"
              v-slot="{ errorMessage, value, field }"
            >
              <q-input
                :dense="$q.platform.is.mobile"
                type="password"
                filled
                autocomplete="off"
                :label="labels.newPasswordConfirmation"
                :model-value="value"
                v-bind="field"
                :error-message="errorMessage"
                :error="!!errorMessage"
              >
                <template v-slot:before><q-icon :name="'password'"/></template>
              </q-input>
            </Field>
          </q-card-section>
          <q-card-section class="row q-mt-none q-pt-none">
            <q-btn
              outline
              color="grey"
              type="submit"
              :loading="in_request"
              :disable="in_request"
              :label="$t('login.recover.submit')"
              class="full-width bg-grey-10 q-mt-sm q-pa-sm q-pr-lg q-pl-lg"
            />
          </q-card-section>
        </Form>
      </q-card-section>
    </q-card>
  </q-intersection>
</template>

<script>
import { useActions, useGetters } from "vuex-composition-helpers";
import PageTitle from "@/modules/main/components/page-title";
import { rules, labels } from "@/shared/form-presets";
import { Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { Notify } from "quasar";
import * as yup from "yup";
import { ref } from "vue";

export default {
  name: "ChangePassword",

  components: {
    PageTitle,
    Field,
    Form,
  },

  setup() {
    const bar = ref(null),
      { t } = useI18n(),
      in_request = ref(false);

    /**
     * definitions for form schema
     */
    const schema = (() => {
      const { password, passwordConfirmation, passwordValidation } = rules;
      return yup.object({
        currentPassword: passwordValidation,
        password: password.label(labels.newPassword),
        passwordConfirmation: passwordConfirmation.label(
          labels.newPasswordConfirmation
        ),
      });
    })();

    const initialValues = {},
      { changePassword } = useActions({
        changePassword: "account/changePassword",
      }),
      { partner } = useGetters({
        partner: "partner/getPartner",
      });

    const onSubmit = (values, actions) => {
      const barRef = bar.value;
      barRef.start();
      setTimeout(() => {
        const barRef = bar.value;
        if (barRef) barRef.stop();
      }, Math.random() * 3000 + 1000);
      in_request.value = true;

      changePassword({
        current_password: values.currentPassword,
        password: values.password,
        password_confirm: values.passwordConfirmation,
      })
        .then(() => {
          Notify.create({
            message: t("notify.success"),
            type: "positive",
            color: "light-blue-8",
            position: "top-right",
          });
        })
        .catch(({ errors, message }) => {
          actions.setFieldValue("password", "");
          actions.setFieldValue("passwordConfirmation", "");

          const notify = (error) => {
            Notify.create({
              message: error,
              position: "top-right",
              color: "red",
              icon: "fas fa-exclamation",
            });
          };

          if (errors) Object.values(errors).forEach(notify);
          if (message) notify(message);
        })
        .finally(() => (in_request.value = false));
    };

    return {
      bar,
      labels,
      schema,
      partner,
      in_request,
      initialValues,
      onSubmit,
    };
  },
};
</script>
